import React from 'react'

export const CloseIcon = () => {
  return (
    <svg
      width='13'
      height='14'
      viewBox='0 0 13 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <line
        y1='-0.5'
        x2='17.8814'
        y2='-0.5'
        transform='matrix(0.686624 -0.727013 0.686624 0.727013 0.72168 14)'
        stroke='#0D2E32'
      />
      <line
        y1='-0.5'
        x2='17.8814'
        y2='-0.5'
        transform='matrix(0.686624 0.727013 -0.686624 0.727013 0 1.00098)'
        stroke='#0D2E32'
      />
    </svg>
  )
}
