/* eslint-disable react/prop-types */
import React from 'react'
import HamburgerIcon from './hamburger'
import { ErrorIcon } from './errorIcon'
import { MailIcon } from './MailIcon'
import { MailIcon2 } from './MailIcon2'
import { LinkedInIcon } from './LinkedInIcon'
import { TwitterIcon } from './TwitterIcon'
import { CloseIcon } from './CloseIcon'
import LogoIcon from './logo'
import LogoIcon2 from './logo2'
import LogoIcon3 from './logo3'
import { FacebookIcon } from './facebookIcon'
import InstagramIcon from './instagramIcon'
import { US } from './us'
import { GB } from './gb'
import { Norway } from './nor'
import {Sweden} from './se';


function Icon (props) {
  switch (props.symbol) {
  case 'hamburger':
    return <HamburgerIcon color={props.color}  />
  case 'logo':
    return <LogoIcon color={props.color} />
  case 'logo2':
    return <LogoIcon2 color={props.color} />
  case 'logo3':
    return <LogoIcon3 color={props.color} />
  case 'errorIcon':
    return <ErrorIcon color={props.color} />
  case 'mailIcon':
      return <MailIcon color={props.color} />
  case 'mailIcon2':
      return <MailIcon2 color={props.color} />
  case 'linkedInIcon':
    return <LinkedInIcon color={props.color} />
  case 'twitterIcon':
      return <TwitterIcon color={props.color} />
  case 'facebookIcon':
      return <FacebookIcon color={props.color} />
  case 'closeIcon':
    return <CloseIcon />
  case 'instagramIcon':
      return <InstagramIcon color={props.color} />
  case 'usIcon':
      return <US />
  case 'gbIcon':
      return <GB />
  case 'noIcon':
      return <Norway />
  case 'seIcon':
    return <Sweden />
  default:
    return <span>Unknown icon: {props.symbol}</span>
  }
}

export default Icon
