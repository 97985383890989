import React from 'react'
import * as styles from './header.module.css'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

export const Item = ({ children, linksTo, type, external }) => {
  if (type) {
    return (
      <li className={styles.itemHighLight}>
        <Link to={linksTo}>{children}</Link>
      </li>
    )
  } else if (external) {
    return (
      <li className={styles.item}>
        <a href={linksTo} target='_blank' rel='noreferrer'>
          {children}
        </a>
      </li>
    )
  } else {
    return (
      <li className={styles.item}>
        <Link to={linksTo}>{children}</Link>
      </li>
    )
  }
}

Item.propTypes = {
  children: PropTypes.node,
  linksTo: PropTypes.string,
  type: PropTypes.string,
  external: PropTypes.bool,
}
