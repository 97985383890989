import React from 'react'
import { cn } from '../../lib/helpers'
import * as styles from './Button.module.css'
import PropTypes from 'prop-types'

export const Button = ({ className, onClick, buttonText }) => {
  return (
    <button onClick={onClick} className={cn(styles.button, className)}>
      {buttonText}
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}
