/* eslint-disable linebreak-style */
import React from 'react'
import * as styles from './SocialMediaIconList.module.css'
import Icon from './icons'
import MFlogo from './icons/Miljofyrtarn.png'

export const SocialMediaIconList = () => {
  return (
    <div className={styles.IconWrapper}>
      <a
        href='https://www.linkedin.com/company/dignio/'
        target='_blank'
        rel='noreferrer'
      >
        <Icon symbol='linkedInIcon' />
      </a>
      <a
        href='https://www.facebook.com/dignionorge/'
        target='_blank'
        rel='noreferrer'
      >
        <Icon symbol='facebookIcon' />
      </a>
      <a href='https://instagram.com/digniouk' target='_blank' rel='noreferrer'>
        <Icon symbol='instagramIcon' />
      </a>
      <div/>
      <a
        href='https://dignio.com/no/press-release/dignio-er-sertifisert-som-miljofyrtarn'
        target='_blank'
        rel='noreferrer'
      >
        <img src={MFlogo} alt="" />
      </a>
    </div>
  )
}

export const ShareSocialMediaIconList = ({ link, color }) => {
  return (
    <div className={styles.IconWrapper}>
      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&url=https%3A//dignio.com/no${link}`}
        target='_blank'
        rel='noreferrer'
      >
        <Icon symbol='linkedInIcon' color={color} />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?text=https%3A//dignio.com/no${link}`}
        target='_blank'
        rel='noreferrer'
      >
        <Icon symbol='twitterIcon' color={color} />
      </a>
      <a href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//dignio.com/no${link}`}
        target='_blank'
        rel='noreferrer'>
        <Icon symbol='facebookIcon' color={color} />
      </a>
      <a href={`mailto:?body=https%3A//dignio.com/no${link}`} target='_blank' rel='noreferrer'>
        <Icon symbol='mailIcon2' color={color} />
      </a>
    </div>
  )
}