import React from 'react'

export const LinkedInIcon = ({ color = '#043940' }) => {
  return (
    <svg
      width='41'
      height='41'
      viewBox='0 0 41 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.701 29.8987V22.0801C31.701 17.8911 29.4646 15.9418 26.4832 15.9418C24.0773 15.9418 22.9992 17.2656 22.3984 18.1941V16.2622H17.8655C17.9256 17.5419 17.8655 29.8987 17.8655 29.8987H22.3984V22.283C22.3984 21.8765 22.4277 21.4693 22.5479 21.1782C22.875 20.3638 23.6207 19.5206 24.8724 19.5206C26.5133 19.5206 27.1688 20.771 27.1688 22.6035V29.8993H31.701V29.8987ZM13.0904 14.401C14.6706 14.401 15.6552 13.3529 15.6552 12.0438C15.6258 10.7067 14.6706 9.68862 13.1205 9.68862C11.5704 9.68862 10.5563 10.706 10.5563 12.0438C10.5563 13.3529 11.5403 14.401 13.0617 14.401H13.0904ZM20.4611 40.4884C9.40075 40.4884 0.433929 31.5215 0.433929 20.4612C0.433929 9.40023 9.40075 0.434082 20.4611 0.434082C31.5214 0.434082 40.4882 9.40023 40.4882 20.4612C40.4882 31.5215 31.5214 40.4884 20.4611 40.4884ZM15.3568 29.8987V16.2622H10.8247V29.8987H15.3568Z'
        fill={color}
      />
    </svg>
  )
}
