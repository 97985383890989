import React from 'react'
import { Link } from 'gatsby'
import Icon from '../icons'
import { cn } from '../../lib/helpers'
import { useGetIsMobile } from '../../hooks/useGetIsMobile'
import { UKHeader } from './uk-header'
import { NOHeader } from './no-header'
import PropTypes from 'prop-types'
import * as styles from './header.module.css'

const Header = ({
  onHideNav,
  onShowNav,
  textColor,
  backgroundColor,
  showNav,
}) => {
  const isMobile = useGetIsMobile()
  return (
    <div className={styles.root}>
      <div style={{ backgroundColor: backgroundColor, color: textColor }}>
        <div className={styles.wrapper}>
          <div className={styles.logoWrapper}>
            <h1 className={styles.branding}>
              <Link to='/'>
                <Icon symbol='logo3' color={textColor} />
              </Link>
            </h1>
          </div>

          <button
            className={styles.toggleNavButton}
            onClick={showNav ? onHideNav : onShowNav}>
            <Icon symbol='hamburger' color={textColor} />
          </button>

          <nav className={cn(styles.nav, showNav && styles.showNav)}>
            <button
              className={styles.toggleNavButton}
              onClick={showNav ? onHideNav : onShowNav}>
              <Icon symbol='hamburger' color='black' />
            </button>
            
              <UKHeader isMobile={isMobile} />
          
          </nav>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  onHideNav: PropTypes.func,
  onShowNav: PropTypes.func,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  showNav: PropTypes.bool,
}

export default Header
