import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './header.module.css'
import { cn } from '../../lib/helpers'

export const Menu = ({ children, bg, keepMenuInViewPort }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [head, ...tail] = React.Children.toArray(children)
  return (
    <ul
      className={styles.menu}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}>
      {head}
      {isOpen && tail.length > 1 && (
        <li>
          <ul
            className={cn(
              styles.open,
              keepMenuInViewPort && styles.keepMenuInViewPort
            )}
            style={{ backgroundColor: bg }}>
            {tail}
          </ul>
        </li>
      )}
    </ul>
  )
}

Menu.propTypes = {
  children: PropTypes.node,
  bg: PropTypes.string,
  keepMenuInViewPort: PropTypes.bool,
}
