import React from 'react'

export const TwitterIcon = ({color = '#043940' }) => {
  return (
    <svg
      width='41'
      height='41'
      viewBox='0 0 41 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='20.5' cy='20.5' r='20.5' fill={color} />
      <path
        d='M32.0817 12.7755C31.6952 12.9469 31.2979 13.0916 30.8919 13.209C31.3726 12.6655 31.739 12.0259 31.9627 11.326C32.0129 11.1691 31.9609 10.9974 31.8319 10.8948C31.7031 10.7921 31.5242 10.7798 31.3824 10.8638C30.5202 11.3752 29.59 11.7427 28.6147 11.9575C27.6322 10.9975 26.2978 10.4512 24.9183 10.4512C22.0063 10.4512 19.6373 12.8201 19.6373 15.7319C19.6373 15.9613 19.6518 16.1893 19.6805 16.4142C16.0671 16.097 12.7078 14.321 10.401 11.4913C10.3188 11.3904 10.1921 11.336 10.0625 11.3464C9.93271 11.3566 9.81614 11.4298 9.75059 11.5422C9.28272 12.345 9.03537 13.2632 9.03537 14.1975C9.03537 15.4699 9.48967 16.6772 10.2922 17.6206C10.0482 17.5361 9.81138 17.4304 9.58538 17.305C9.46404 17.2374 9.31597 17.2385 9.19543 17.3076C9.07481 17.3767 8.99918 17.5038 8.99601 17.6428C8.99545 17.6662 8.99545 17.6896 8.99545 17.7133C8.99545 19.6127 10.0177 21.3227 11.5806 22.2547C11.4463 22.2413 11.3121 22.2218 11.1788 22.1964C11.0414 22.1701 10.9 22.2183 10.8074 22.3231C10.7145 22.4278 10.6838 22.5738 10.7266 22.7072C11.3051 24.5133 12.7945 25.8417 14.595 26.2468C13.1017 27.1821 11.3939 27.672 9.60109 27.672C9.22701 27.672 8.85079 27.65 8.48259 27.6065C8.29967 27.5847 8.12478 27.6927 8.06248 27.8667C8.00019 28.0407 8.06621 28.2347 8.22183 28.3345C10.5249 29.8112 13.188 30.5917 15.9229 30.5917C21.2995 30.5917 24.663 28.0563 26.5377 25.9294C28.8755 23.2773 30.2163 19.7669 30.2163 16.2985C30.2163 16.1536 30.2141 16.0072 30.2096 15.8614C31.132 15.1665 31.9261 14.3255 32.5722 13.3589C32.6704 13.2121 32.6598 13.018 32.546 12.8829C32.4325 12.7477 32.2432 12.704 32.0817 12.7755Z'
        fill='white'
      />
    </svg>
  )
}
