import { format, parseISO } from 'date-fns'

export function cn (...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs ({ slug }) {
  return (slug || {}).current
}

export function getBlogUrl (publishedAt, slug) {
  return `/blog/${format(parseISO(publishedAt), 'yyyy/MM')}/${slug.current || slug}/`
}

export function buildImageObj(source) {
  if (source) {
    const imageObj = {
      asset: { _ref: source?.asset?._ref || source?.asset?._id }
    }

    if (source?.crop) imageObj.crop = source.crop
    if (source?.hotspot) imageObj.hotspot = source.hotspot

    return imageObj
  }
  else return;
}

export const isValidEmail = (email) => {
  const emailRegExp = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)
  return emailRegExp.test(email)
}
export const isValidCountryCode = (countryCode) => {
  const countryCodeRegExp = new RegExp(/^(\+?\d{1,3}|\d{1,4})$/)
  return countryCodeRegExp.test(countryCode)
}
export const isValidPhoneNumber = (phoneNumber) => {
  const digitsRegExp = new RegExp(/^[0-9]+$/)
  return digitsRegExp.test(phoneNumber)
}
