import React from 'react'
import * as styles from './InfoMessage.module.css'
import Icon from './icons'
import PropTypes from 'prop-types'

export const InfoMessage = ({ message }) => {
  return (
    <div className={styles.errorMessageWrapper}>
      <Icon symbol='errorIcon' />
      <span className={styles.errorMessage}>{message}</span>
    </div>
  )
}

InfoMessage.propTypes = {
  message: PropTypes.string.isRequired,
}
