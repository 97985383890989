import React, { useState } from 'react'
import { Item } from './menu-item'
import { Menu } from './menu'
import PropTypes from 'prop-types'
import * as styles from './header.module.css'
import { Button } from '../Button/Button'
import { ContactModal } from '../contact/ContactModal'

export const UKHeader = ({ isMobile }) => {
  const [showContactModal, setShowContactModal]= useState(false)
  return (
    <div className={styles.menuWrap}>
      <Menu>
        <Item linksTo={'/solution/'}>Solution</Item>

      </Menu>

      {/* <Menu>
        <Item linksTo={'/stories/'}>Stories</Item>
      </Menu> */}
      <Menu keepMenuInViewPort>
        <Item linksTo={'/about/'}>About</Item>
        {!isMobile && <Item linksTo={'/contact/'}>Contact</Item>}
        <Item linksTo={'https://dignio.homerun.co/'} external>
          Join us
        </Item>
       
      </Menu>
      {!isMobile && <Menu className={styles.button}>

        <button className={styles.button} onClick={() => setShowContactModal(true)}>Book a demo</button>
      </Menu>}
      {isMobile && <Menu>
        <Item linksTo={'/contact/'}>Contact</Item>
      </Menu>}
      {/* <button className={styles.button} onClick={() => setShowContactModal(true)}>Book a demo</button> */}
      {showContactModal && (
          <ContactModal onClose={() => setShowContactModal(false)} />
        )}
    </div>

  )
}

UKHeader.propTypes = {
  isMobile: PropTypes.bool,
}
