// extracted by mini-css-extract-plugin
export var branding = "layout-module--branding--H3uN8";
export var companyAddress = "layout-module--companyAddress--2cugI";
export var container = "layout-module--container--eLBMS";
export var content = "layout-module--content--K-wVB";
export var expandedCol = "layout-module--expandedCol--oiX6L";
export var extras = "layout-module--extras--VcSCz";
export var footer = "layout-module--footer--rX0Dt";
export var footerHeadline = "layout-module--footerHeadline--QRWME";
export var footerLine = "layout-module--footerLine---9mdb";
export var footerLower = "layout-module--footerLower--j2GeD";
export var hero = "layout-module--hero--OVJey";
export var item = "layout-module--item--b12hb";
export var link = "layout-module--link--NDVFt";
export var narrowWith = "layout-module--narrowWith--hvPSE";
export var siteInfo = "layout-module--siteInfo--MNT63";
export var sites = "layout-module--sites--Xaa9i";
export var social = "layout-module--social--SnkQv";
export var solution = "layout-module--solution--cPMT+";