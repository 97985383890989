/* eslint-disable react/prop-types */
import React from 'react'

const Logo3 = (props) => (

  <svg width="116" height="31" viewBox="0 0 116 31" fill="none">
    <path d="M8.61043 15.8284C9.91097 15.8284 11.1737 15.5038 12.3043 14.8827C13.4043 15.4861 14.6612 15.8284 15.9952 15.8284C20.2935 15.8284 23.7911 12.2774 23.7911 7.91346C23.7911 3.54955 20.2935 0 15.9952 0C14.6612 0 13.4028 0.342267 12.3043 0.945661C11.1723 0.326039 9.91097 0 8.61043 0C4.31211 0 0.814453 3.55102 0.814453 7.91493C0.814453 12.2788 4.31211 15.8284 8.61043 15.8284ZM21.3296 7.91493C21.3296 10.9009 18.9363 13.3307 15.9952 13.3307C13.0541 13.3307 10.6608 10.9009 10.6608 7.91493C10.6608 4.92895 13.0541 2.49914 15.9952 2.49914C18.9363 2.49914 21.3296 4.92895 21.3296 7.91493ZM8.61043 2.49914C9.12629 2.49914 9.63342 2.57438 10.1188 2.71896C8.9243 4.11016 8.1992 5.92772 8.1992 7.91346C8.1992 9.8992 8.9243 11.7168 10.1188 13.108C9.63342 13.2525 9.12629 13.3278 8.61043 13.3278C5.66932 13.3278 3.27603 10.898 3.27603 7.91198C3.27603 4.92599 5.66932 2.49914 8.61043 2.49914Z" 
      fill={props.color}  />
    <path d="M21.6812 17.3604H14.2949H2.9241C1.91126 17.3604 1.03792 18.1182 0.894062 19.1228C0.847562 19.4485 0.821405 19.7799 0.815593 20.1114C0.742936 24.403 4.15345 27.9777 8.41986 28.0799C8.4838 28.0813 8.54774 28.0828 8.61168 28.0828C9.92531 28.0828 11.1866 27.7572 12.3128 27.1453C13.3867 27.7294 14.5797 28.0507 15.8062 28.0799C15.8701 28.0813 15.934 28.0828 15.998 28.0828C18.0454 28.0828 19.9723 27.2957 21.4414 25.8545C22.957 24.368 23.7911 22.3777 23.7911 20.2501C23.7911 19.8719 23.7635 19.4923 23.7112 19.1214C23.5659 18.1153 22.694 17.3604 21.6812 17.3604ZM3.33097 19.4748V19.4762C3.33097 19.4748 3.33097 19.4748 3.33097 19.4748ZM8.47944 25.6048C5.56154 25.5347 3.2278 23.0888 3.27721 20.1523C3.27866 20.0457 3.28447 19.9406 3.29174 19.834H13.9287C13.9389 19.9712 13.9447 20.1085 13.9447 20.2458C13.9447 21.7016 13.3736 23.064 12.3375 24.0803C11.3 25.0981 9.92822 25.6384 8.47944 25.6048ZM19.7209 24.0818C18.6848 25.0981 17.3145 25.6384 15.8643 25.6048C15.3964 25.5931 14.9372 25.5186 14.4925 25.3872C15.7306 23.962 16.4049 22.1601 16.4049 20.2472C16.4049 20.11 16.4005 19.9727 16.3932 19.8354H21.3136C21.3237 19.9727 21.3295 20.11 21.3295 20.2472C21.3281 21.7016 20.7585 23.064 19.7209 24.0818Z" 
      fill={props.color}  />
    <path d="M34.5137 16.2852V16.0419C34.5137 11.3044 37.6446 8.32956 41.4128 8.32956C43.4194 8.32956 45.181 9.20947 46.3666 10.7289V2.04199H49.8315V23.6639H46.457V21.5078C45.2714 23.087 43.4792 23.9975 41.4113 23.9975C37.5527 23.9975 34.5137 20.9601 34.5137 16.2852ZM46.5197 16.2852V16.0419C46.5197 13.1268 44.605 11.2752 42.2951 11.2752C39.9545 11.2752 38.0398 13.1268 38.0398 16.0419V16.2852C38.0398 19.1391 39.8933 21.0518 42.2951 21.0518C44.6648 21.0518 46.5197 19.1391 46.5197 16.2852Z" 
      fill={props.color}  />
    <path d="M56.9801 3.88859C56.9801 5.20773 55.9619 6.21329 54.6669 6.21329C53.3719 6.21329 52.3848 5.20773 52.3848 3.88859C52.3848 2.53779 53.3719 1.53223 54.6669 1.53223C55.9619 1.53223 56.9801 2.53779 56.9801 3.88859ZM56.4251 23.9983H52.9087V8.47618H56.4251V23.9983Z" 
      fill={props.color}  />
    <path d="M66.0949 27.3049C68.6736 27.3049 70.3719 25.7717 70.3719 23.1954V21.2944C69.1582 22.7054 67.3988 23.5029 65.4576 23.5029C61.6361 23.5029 58.5117 20.4969 58.5117 15.9593V15.7135C58.5117 11.2053 61.6361 8.16992 65.4576 8.16992C67.3988 8.16992 69.1582 8.96739 70.3719 10.4093V8.50539H73.8295V22.9188C73.8295 27.427 70.7051 30.1254 66.0949 30.1254C62.2735 30.1254 59.5435 28.2244 58.6645 24.8815L62.0916 24.5137C62.6373 26.2926 64.0634 27.3049 66.0949 27.3049ZM70.5232 15.9578V15.7121C70.5232 12.9827 68.6736 11.1435 66.2768 11.1435C63.8801 11.1435 62.061 12.9842 62.061 15.7121V15.9578C62.061 18.718 63.8801 20.5263 66.2768 20.5263C68.6736 20.5263 70.5232 18.718 70.5232 15.9578Z" 
      fill={props.color}  />
    <path d="M80.5307 23.9983H76.8926V8.51431H80.4663V10.8032H80.5307C81.6163 9.07977 83.3388 8.16992 85.5085 8.16992C88.2218 8.16992 91.1892 9.67381 91.1892 13.9057V23.9983H87.5511V14.9088C87.5511 12.5583 86.3706 11.2108 84.3601 11.2108C82.2226 11.2108 80.5307 12.7462 80.5307 15.16V23.9983Z" 
      fill={props.color}  />
    <path d="M97.8278 3.88859C97.8278 5.20773 96.8095 6.21329 95.5145 6.21329C94.2196 6.21329 93.2324 5.20773 93.2324 3.88859C93.2324 2.53779 94.2196 1.53223 95.5145 1.53223C96.8095 1.53223 97.8278 2.53779 97.8278 3.88859ZM97.2728 23.9983H93.7563V8.47618H97.2728V23.9983Z" 
      fill={props.color}  />
    <path d="M99.3594 16.207V15.9612C99.3594 11.2679 102.932 8.16992 107.273 8.16992C111.646 8.16992 115.188 11.2679 115.188 15.9612V16.207C115.188 20.9312 111.646 23.9983 107.273 23.9983C102.93 23.9983 99.3594 20.9298 99.3594 16.207ZM111.646 16.207V15.9612C111.646 13.0781 109.737 11.1457 107.273 11.1457C104.81 11.1457 102.899 13.0781 102.899 15.9612V16.207C102.899 19.0901 104.808 21.0225 107.273 21.0225C109.736 21.0225 111.646 19.0901 111.646 16.207Z" 
      fill={props.color}  />
  </svg>

    
)

export default Logo3


