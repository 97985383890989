import React from 'react'
export const MailIcon = ({ color = 'none' }) => (
  <svg
    width='58'
    height='60'
    viewBox='0 0 58 60'
    fill={color}
    xmlns='http://www.w3.org/2000/svg'>
    <ellipse cx='28.9801' cy='30' rx='28.7896' ry='30' fill='#FFD9D2' />
    <path
      d='M13.1465 22.667L27.0279 32.3103C27.6061 32.7123 28.2857 32.9269 28.9808 32.9269C29.6759 32.9269 30.3554 32.7123 30.9337 32.3103L44.8151 22.667M16.6652 42.8337H41.2963C42.2296 42.8337 43.1246 42.4473 43.7845 41.7597C44.4443 41.0721 44.8151 40.1395 44.8151 39.167V20.8337C44.8151 19.8612 44.4443 18.9286 43.7845 18.2409C43.1246 17.5533 42.2296 17.167 41.2963 17.167H16.6652C15.732 17.167 14.837 17.5533 14.1771 18.2409C13.5172 18.9286 13.1465 19.8612 13.1465 20.8337V39.167C13.1465 40.1395 13.5172 41.0721 14.1771 41.7597C14.837 42.4473 15.732 42.8337 16.6652 42.8337Z'
      stroke='#333333'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
