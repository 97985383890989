import React from 'react'
import Icon from '../icons'
import * as styles from './Modal.module.css'
import { cn } from '../../lib/helpers'
import PropTypes from 'prop-types'
import FocusTrap from 'focus-trap-react'

export const Modal = ({ title, onClose, children }) => {
  return (
    <FocusTrap>
      <div className={styles.modalContainer} onClick={onClose}>
        <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
          <div className={cn(styles.header, !title && styles.onlyCloseIcon)}>
            {title && <h3 className={styles.title}>{title}</h3>}
            <button onClick={onClose}>
              <Icon symbol='closeIcon' />
            </button>
          </div>
          {children}
        </div>
      </div>
    </FocusTrap>
  )
}

Modal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}
