import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './container.module.css'

const Container = ({
  backgroundColor,
  fullwidth,
  wide,
  textColor,
  children,
}) => {
  if (fullwidth) {
    return (
      <div style={{ backgroundColor: backgroundColor, color: textColor }}>
        <div className={styles.root}>{children}</div>
      </div>
    )
  }

  if (wide) {
    return (
      <div style={{ backgroundColor: backgroundColor, color: textColor }}>
        <div className={styles.rootWide}>{children}</div>
      </div>
    )
  } else {
    return <div className={styles.root}>{children}</div>
  }
}

Container.defaultProps = {
  textColor: 'black',
  backgroundColor: 'white',
  title: '',
}

Container.propTypes = {
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  fullwidth: PropTypes.bool,
  wide: PropTypes.bool,
  children: PropTypes.node,
}
export default Container
