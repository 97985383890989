import React from 'react'
import Header from './Header/header'
import { Link } from 'gatsby'
import Icon from './icons'

import '../styles/layout.css'
import * as styles from './layout.module.css'
import Footer from './footer'

const Item = ({ children, linksTo}) => {

  return (
    <li className={styles.item}>
      <Link to={linksTo}>{children}</Link>
    </li>
  )
  
}
const Layout = ({ children, backgroundImage, backgroundColor, textColor, companyInfo, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
  
    {backgroundImage && 
    <div className={styles.hero} style={{backgroundImage: `url(${backgroundImage})`,
    }} >
    </div>
    }


    <Header textColor={textColor} backgroundColor={backgroundColor} siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />

    <div className={styles.content}>
      {children}
    </div>

    <Footer />


  </>
)

Layout.defaultProps = {
  textColor: 'black',
}

export default Layout
