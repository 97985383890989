/* eslint-disable react/prop-types */
import React from 'react'

const Logo2 = (props) => (

  <svg version="1.1" id="Layer_1" x="0px" y="0px"
	 viewBox="0 0 50 50">

    <g>
      <path d="M6.7,31.56v1.55c0,7.18,5.45,13.03,12.14,13.03c2.39,0,4.7-0.75,6.69-2.16c1.98,1.41,4.29,2.16,6.68,2.16
			c6.69,0,12.14-5.85,12.14-13.03v-1.55H6.7z M32.21,43.04c-1.52,0-3.02-0.43-4.35-1.24c1.69-2.01,2.74-4.47,3.05-7.14h10.23
			C40.45,39.46,36.66,43.04,32.21,43.04z M23.44,41.65h-0.01c-1.39,0.91-2.97,1.39-4.59,1.39c-4.45,0-8.24-3.58-8.93-8.38h17.87
			c-0.29,2.01-1.13,3.85-2.44,5.34C24.78,40.65,24.13,41.2,23.44,41.65z"
      fill={props.color} 
      />
	
      <path d="M32.05,2.88c-2.33,0-4.59,0.69-6.55,1.99c-1.95-1.3-4.21-1.99-6.55-1.99c-6.53,0-11.84,5.32-11.84,11.85
			c0,6.53,5.31,11.85,11.84,11.85c2.35,0,4.62-0.69,6.55-1.99c1.95,1.3,4.21,1.99,6.55,1.99c6.53,0,11.85-5.32,11.85-11.85
			C43.9,8.2,38.58,2.88,32.05,2.88z M32.05,23.58c-1.55,0-3.09-0.42-4.45-1.22c-0.71-0.41-1.36-0.92-1.92-1.5
			c-1.59-1.67-2.47-3.84-2.47-6.13s0.88-4.46,2.47-6.13c0.57-0.59,1.22-1.09,1.91-1.49h0.01c1.37-0.81,2.91-1.23,4.45-1.23
			c4.88,0,8.85,3.97,8.85,8.85S36.93,23.58,32.05,23.58z M18.95,23.58c-4.87,0-8.84-3.97-8.84-8.85s3.97-8.85,8.84-8.85
			c1.45,0,2.89,0.37,4.2,1.07c-1.9,2.16-2.94,4.91-2.94,7.78s1.04,5.62,2.94,7.79C21.85,23.21,20.41,23.58,18.95,23.58z"
      fill={props.color} 
      />
    </g>


  </svg>


)

export default Logo2






