import { useState, useEffect } from 'react'

export const useGetIsMobile = () => {
  const [width, setWidth] = useState(null)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => setWidth(window.innerWidth)
      handleResize()
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])
  return (width && width < 675)
}
