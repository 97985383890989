import React from 'react'
import { cn } from '../lib/helpers'
import PropTypes from 'prop-types'
import * as styles from './Input.module.css'

export const Input = ({
  error,
  onChange,
  value = '',
  placeholder,
  name,
  type = 'text',
  className,
  required,
}) => {
  return (
    <input
      className={cn(
        styles.inputField,
        error && styles.error,
        className && className
      )}
      type={type}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required={required}
    />
  )
}
Input.propTypes = {
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
}
